import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState } from "draft-js";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import { Button, Label, Loading } from "../../../../../components/ui-components";
import { useFetch } from "../../../../../hooks";
import { REQUEST_TYPE } from "../../../../../consts/enums";
import { prepareDraft } from "../../../../../consts/prepareDraft";
import Attachments from "../../../../../Admin/pages/components/Attachments/Attachments";
import {
  AssignedWrapper,
  CommentsViewer,
  HeaderEditor,
  MenuView,
  PageTextEditor,
} from "../../../../../Admin/pages/components";
import { getDocumentBacklinks } from "../../../../../api/documentApi";
import {
  attachExistingFile,
  createMeetingComment,
  detachDocument,
  editMeeting,
  getMeeting,
} from "../../../../../api/meetingsAPI";
import { MeetingIcon, PrivateNoteIcon } from "../../../../../AdminComponents/icons";
import DueDateTimeRange from "../../../../../Admin/pages/components/DueDateTimeRange/DueDateTimeRange";

import styles from "./MeetingView.module.scss";

const initEditMode = {
  title: false,
  description: false,
  privateNote: false,
  location: false,
};

const MeetingView = ({ onChangeCurrentMeeting, onDelete }) => {
  const { meetingId, id: auditId } = useParams();
  const { t } = useTranslation("dashboard", { keyPrefix: "meetings" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });

  const user = useSelector((state) => state.user.user_data);
  const [meetingsData, setMeetingsData] = useState(null);
  const [editMeetingsData, setEditMeetingsData] = useState({
    title: "",
    description: "",
    privateNote: "",
    location: "",
  });
  const [comment, setComment] = useState("");
  const [progress, setProgress] = useState(0);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [privateNoteEditorState, setPrivateNoteEditorState] = useState(EditorState.createEmpty());
  const { isLoading, error, data } = useFetch(getMeeting, auditId, meetingId);
  const { data: documents } = useQuery({
    staleTime: Infinity,
    queryKey: ["documents"],
    queryFn: () => getDocumentBacklinks(auditId),
  });

  const isOrganiser = (email) => user.email === email;

  const [editMode, setEditMode] = useState(initEditMode);

  useEffect(() => {
    if (data && !error && !isLoading) {
      setMeetingsData({ ...data.meeting, attachments: [] });
      const {
        title,
        description,
        privateNote: { note },
        location,
      } = data.meeting;
      setEditMeetingsData({ title, description, privateNote: note, location });
      setEditorState(prepareDraft(description));

      setPrivateNoteEditorState(prepareDraft(note ? note : ""));
    }
  }, [data, isLoading, error]);

  useEffect(() => {
    setEditMode(initEditMode);
  }, [meetingId]);

  const updateMeeting = async (body, config) => {
    try {
      const corruptedKeys = {
        assignedUsers: "assignedUsers",
        assignedRoles: "assignedRoles",
        attachments: "relatedDocs",
      };
      const hasCorruptedKeys = Object.keys(corruptedKeys).some((key) => body.hasOwnProperty(key));
      if (!hasCorruptedKeys) {
        onChangeCurrentMeeting(meetingId, body);
      }
      const response = await editMeeting(auditId, meetingId, body, config);
      if (hasCorruptedKeys) {
        const values = Object.values(corruptedKeys).reduce((acc, val) => {
          if (response.request.hasOwnProperty(val)) {
            acc[val] = response.request[val];
          }
          return acc;
        }, {});
        onChangeCurrentMeeting(meetingId, values);
      }
      return response;
    } catch (err) {
      alert(err.message);
    }
  };

  const handleEditMeetingsData = (e) => {
    const { name, value } = e.target;
    setEditMeetingsData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAssignedUsersAndRoles = (assignedUsers = [], assignedRoles = []) => {
    setMeetingsData((prevValue) => ({
      ...prevValue,
      assignedUsers,
      assignedRoles,
    }));
  };

  const openEditMode = (name) => {
    setEditMode((prev) => ({ ...prev, [name]: true }));
  };

  const closeEditMode = (event, name) => {
    event.stopPropagation();
    setEditMode((prev) => ({ ...prev, [name]: false }));
  };

  const addComment = async (event) => {
    if (!comment) {
      return;
    }
    if (event.key === "Enter") {
      try {
        const response = await createMeetingComment(auditId, meetingId, comment);
        setMeetingsData((prev) => ({
          ...prev,
          comments: [...prev.comments, response.comment],
        }));
      } catch (err) {
        console.error(err);
      }
      setComment("");
    }
  };

  const handleComment = (e) => {
    setComment(e.target.value);
  };

  const onEditorStateChange = (editModeName, editorState) => {
    if (editMode[editModeName]) {
      const forFormik = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      setEditMeetingsData((prev) => ({
        ...prev,
        [editModeName]: forFormik,
      }));
      if (editModeName === "privateNote") {
        setPrivateNoteEditorState(editorState);
      } else {
        setEditorState(editorState);
      }
    }
  };
  const acceptChanges = async (event, name) => {
    closeEditMode(event, name);
    await updateMeeting({ [name]: editMeetingsData[name] });
    setMeetingsData((prev) => ({
      ...prev,
      [name]: editMeetingsData[name],
    }));
  };
  const discardChanges = async (event, name) => {
    setEditMeetingsData((prev) => ({
      ...prev,
      [name]: meetingsData[name],
    }));
    closeEditMode(event, name);
  };

  const handleAssignedUsers = async ({ assignedUsers, assignedRoles }) => {
    handleAssignedUsersAndRoles(assignedUsers, assignedRoles);

    await updateMeeting({
      assignedUsers: assignedUsers.map((user) => user.id),
      assignedRoles: assignedRoles.map((role) => role.id),
    });
  };

  const removeAssignee = async (type, userId) => {
    const newAssignees = meetingsData[type].filter((user) => user.id !== userId);

    setMeetingsData((prevValue) => ({
      ...prevValue,
      [type]: newAssignees,
    }));

    await updateMeeting({
      [type]: newAssignees.map((user) => user.id),
    });
  };

  const addAttachmentFile = async (files) => {
    setMeetingsData((prev) => ({
      ...prev,
      attachments: [...(prev.attachments || []), ...files],
    }));
    const res = await updateMeeting(
      { files: files },
      {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
          setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
          setProgress(progress);
        },
      }
    );
    setMeetingsData({ ...res.request, attachments: [] });
    setProgress(0);
  };

  const attachExistingDocument = async (name, document) => {
    setMeetingsData((state) => ({
      ...state,
      files: [...state.files, document],
    }));
    await attachExistingFile(auditId, meetingId, { documentId: document.id });
  };

  const handleDeleteDocument = async (id) => {
    try {
      await detachDocument(auditId, meetingId, id);
      setMeetingsData((prev) => ({
        ...prev,
        files: prev.files.filter((el) => el.id !== id),
      }));
    } catch (e) {
      console.log(e);
    }
  };

  const changeDateTime = (newState) => {
    setMeetingsData((state) => ({
      ...state,
      ...newState,
    }));
  };
  if (error) {
    return <div> Something went wrong, please try again later</div>;
  }

  return !isLoading && meetingsData && editMeetingsData ? (
    <div className={styles.wrapper}>
      <div className={styles.topContainer}>
        <div className={styles.headerSection}>
          <HeaderEditor
            activeEdit={editMode.title}
            discardChanges={discardChanges}
            acceptChanges={acceptChanges}
            onOpenEdit={openEditMode}
            name='title'
            onChange={handleEditMeetingsData}
            value={editMeetingsData.title}
          />
          <MenuView onDelete={onDelete} deleteText={t("delete_meeting")} />
        </div>
        <div className={styles.bodySection}>
          <div className={styles.containerAssigned}>
            <AssignedWrapper
              creatorLabel={t("organiser")}
              assignedLabel={t("attenedees")}
              assignButtonLabel={tGlobal("assign_user")}
              creator={meetingsData?.organiser}
              assignedRoles={meetingsData.assignedRoles}
              assignedUsers={meetingsData.assignedUsers}
              onRemove={removeAssignee}
              isOrganiser={isOrganiser}
              handleAssignUsers={handleAssignedUsers}
            />
          </div>

          <DueDateTimeRange
            date={meetingsData.date}
            startTime={meetingsData.startTime}
            endTime={meetingsData.endTime}
            onChange={updateMeeting}
            setData={changeDateTime}
          />

          {meetingsData.location && (
            <div className={styles.column}>
              <Label>{t("location")}</Label>
              <div className={styles.row} style={{ gap: "15px" }}>
                <Button
                  style={{
                    width: "113px",
                    gap: "10px",
                    padding: 0,
                    height: "36px",
                    pointerEvents: "none",
                  }}
                  color='gray'
                  secondary
                >
                  <MeetingIcon />
                  {t("zoom_room")}
                </Button>
                <Button
                  style={{ whiteSpace: "noWrap", width: "fit-content" }}
                  onClick={() => window.open(meetingsData.location)}
                  color='blue'
                  secondary
                >
                  {t("join_the_meeting")}
                </Button>
              </div>
            </div>
          )}
          <PageTextEditor
            label={t("description")}
            name='description'
            discardChanges={discardChanges}
            acceptChanges={acceptChanges}
            openEditMode={openEditMode}
            value={editMeetingsData.description}
            isEditMode={editMode.description}
            editorState={editorState}
            readOnly={!editMode.description}
            onEditorStateChange={(editorState) => onEditorStateChange("description", editorState)}
            bordered
          />
          <Attachments
            progress={progress}
            isBoxioRequest={meetingsData.request_type === REQUEST_TYPE.DATA_TRANSFER_REQUEST}
            relatedDocs={meetingsData.files}
            attachments={meetingsData.attachments}
            onRemoveFile={handleDeleteDocument}
            onAddFile={addAttachmentFile}
            onAttach={attachExistingDocument}
            searchOptions={documents || []}
            multiple
            hasSearch
          />
          <PageTextEditor
            label={
              <>
                <PrivateNoteIcon /> {t("private_notes")}
              </>
            }
            name='privateNote'
            discardChanges={discardChanges}
            acceptChanges={acceptChanges}
            openEditMode={openEditMode}
            value={editMeetingsData.privateNote}
            isEditMode={editMode.privateNote}
            editorState={privateNoteEditorState}
            readOnly={!editMode.privateNote}
            onEditorStateChange={(editorState) => onEditorStateChange("privateNote", editorState)}
            bordered
          />
        </div>
        <div className={styles.inner}>
          <Label>{tGlobal("log_and_comment")}</Label>
          <CommentsViewer
            tKeyPrefix='meetings'
            value={comment}
            onChange={handleComment}
            onSubmit={addComment}
            comments={meetingsData.comments}
            createdAt={meetingsData.createdAt}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.loadingWrapper}>
      <Loading />
    </div>
  );
};

export default MeetingView;
