import { Input, Tag } from "antd";
import clsx from "clsx";
import React, { ChangeEvent, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactComponent as ArrowLeftIcon } from "../../../../../assets/icons/arrow-left.svg";
import { ReactComponent as IdentityIcon } from "../../../../../assets/icons/identity-icon.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/icons/plus.svg";
import { ReactComponent as SearchIcon } from "../../../../../assets/icons/search.svg";

import CustomAvatar from "../../../../../components/CustomAvatar";
import { Button, Label } from "../../../../../components/ui-components";
import UserNameWithStatus from "../../../../../components/UserNameWithStatus";

import toFullName from "../../../../../consts/toFullName";
import { RootState } from "../../../../../store";
import { Document } from "../../../../../types/document";

import styles from "./DocumentViewSignatures.module.scss";

type Props = {
  document: Document | null;
};

export default function DocumentViewSignatures({ document }: Props) {
  const { t } = useTranslation("dashboard", { keyPrefix: "documents" });
  const user = useSelector((state: RootState) => state.user.user_data);

  const [showSigners, setShowSigners] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredSigners = document?.access?.filter((signer) => {
    const fullName = signer.user.firstName
      ? `${signer.user.firstName} ${signer.user.lastName}`
      : signer.user.email;
    return fullName.toLowerCase().includes(searchQuery);
  });

  return (
    <>
      {showSigners && (
        <div className={styles.newSignersWrapper}>
          <div className={styles.newSignersHero}>
            <Button color='gray' icon={<ArrowLeftIcon />} onClick={() => setShowSigners(false)} />
            <Input prefix={<SearchIcon />} onChange={handleSearch} />
            <div className={styles.description}>
              <Trans t={t} i18nKey='signers_list_description' components={{ b: <b /> }} />
            </div>
          </div>
          <div className={styles.newSignersBody}>
            {filteredSigners?.map((signer) => (
              <div className={styles.newSignerItem} key={signer.id}>
                {signer.user.homeTeam?.name && (
                  <div className={styles.companyName}>{signer.user.homeTeam.name}</div>
                )}
                <div className={styles.userName}>
                  <UserNameWithStatus
                    name={
                      signer.user.firstName
                        ? `${signer.user.firstName} ${signer.user.lastName}`
                        : signer.user.email
                    }
                    role={signer.user.role}
                    signatoryStatus={signer?.user?.signatoryStatus}
                    signatory={false}
                  />
                </div>
                {signer.user.homeTeam?.signatory && (
                  <div>
                    <Tag className={styles.companySignatory}>{signer.user.homeTeam?.signatory}</Tag>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <Label>{t("table_header_signatures")}</Label>
          <Button color='lightBlue' icon={<PlusIcon />} onClick={() => setShowSigners(true)} />
        </div>
        {document?.signers?.length ? (
          <div className={styles.list}>
            {document.signers.map((signer) => {
              const currentUser =
                signer.teamMember?.user || signer.auditorMember?.member.user || {};
              const isMe = currentUser.email === user.email;

              return (
                <div className={styles.signer}>
                  <div className={styles.userInfo}>
                    <CustomAvatar user={currentUser} width={24} height={24} />
                    <div className={styles.userName}>
                      {toFullName(currentUser)} {isMe && "(You)"}
                    </div>
                  </div>
                  <span className={clsx(styles.status, styles[signer.status])}>
                    {signer.status.split("_").join(" ")}
                  </span>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={styles.empty}>
            <IdentityIcon />
            <div className={styles.emptyText}>{t("no_signatures_requested")}</div>
          </div>
        )}
      </div>
    </>
  );
}
