import { Button, Input } from "antd";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";

import { Document } from "../../types/document";
import { useDocuments } from "../../hooks/services/useDocuments";

import EditButtons from "../../Admin/pages/components/EditButtons/EditButtons";
import useOutsideClick from "../../hooks/useOutsideClick";

import { PenIcon } from "../../assets/icons";

import styles from "./EditableDocumentName.module.scss";

type QueryParamsProps = {
  id: string;
};

type EditedDocumentNameProps = {
  size?: "small" | "medium" | "large";
  className?: string;
  id: Document["id"];
  name: Document["name"];
  onSave?: (title: Document["name"]) => void;
};

export default function EditableDocumentName({
  className,
  name,
  id,
  size = "medium",
  onSave,
}: EditedDocumentNameProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { id: idAudit } = useParams<QueryParamsProps>();
  const { updateDocumentName } = useDocuments();

  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(name);

  useEffect(() => {
    setTitle(name);
  }, [name]);

  const handlePreventDefault = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleEdit = (e: MouseEvent) => {
    handlePreventDefault(e);
    setIsEditing(true);
  };

  const handleSave = (e: MouseEvent) => {
    handlePreventDefault(e);
    updateDocumentName({
      body: {
        name: title,
      },
      documentId: id,
      auditId: idAudit,
    });
    onSave?.(title);
    setIsEditing(false);
  };

  const handleCancelEdit = (e: MouseEvent) => {
    handlePreventDefault(e);
    setTitle(name);
    setIsEditing(false);
  };

  const handleSaveOnClose = () => {
    if (isEditing) {
      setTitle(title);
      updateDocumentName({
        body: {
          name: title,
        },
        documentId: id,
        auditId: idAudit,
      });
      onSave?.(title);
      setIsEditing(false);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setTitle(e.target.value);
  };

  const submitForm = (e: MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSave(e);
  };

  useOutsideClick(ref, handleSaveOnClose);

  return (
    <div
      ref={ref}
      className={classNames(
        styles.nameWrapper,
        styles[size],
        isEditing && styles.nameWrapperEditing
      )}
    >
      <div className={classNames(styles.inner, isEditing && styles.innerAbsolute)}>
        {isEditing ? (
          <form onSubmit={submitForm}>
            <Input
              className={styles.input}
              value={title}
              onChange={handleChange}
              onClick={handlePreventDefault}
            />
            <EditButtons
              className={styles.actionButtons}
              discardClick={handleCancelEdit}
              acceptClick={handleSave}
            />
          </form>
        ) : (
          <div className={classNames(styles.name, className)} onClick={handleEdit}>
            {title}
            <Button type='text' className={styles.editButton} icon={<PenIcon />} size='small' />
          </div>
        )}
      </div>
    </div>
  );
}
