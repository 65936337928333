// Signature.tsx
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import dayjs from "dayjs";
import SignatureCanvas from "react-signature-canvas";
import { useTranslation } from "react-i18next";

import styles from "./Signature.module.scss";
import dataURLToFile from "../../helpers/dataURLToFile";

type SignatureProps = {
  title?: string;
  companyName?: string;
  date?: Date;
  image?: string;
  onSignatureChange?: () => void;
};

const Signature = forwardRef<{ getSignatureFile: () => File }, SignatureProps>(
  ({ title = "", companyName = "aivo Revisions AG", date, image, onSignatureChange }, ref) => {
    const { t } = useTranslation("dashboard", { keyPrefix: "global" });
    const sigCanvasRef = useRef<SignatureCanvas>(null);

    useImperativeHandle(ref, () => ({
      getSignatureFile: () => {
        if (sigCanvasRef.current) {
          const dataURL = sigCanvasRef.current.getTrimmedCanvas().toDataURL("image/png");
          return dataURLToFile(dataURL, "signature.png");
        }
        return new File([], "empty_signature.png", { type: "image/png" });
      },
      clearCanvas: () => {
        if (sigCanvasRef.current) {
          sigCanvasRef.current.clear();
        }
      },
    }));

    return (
      <div className={styles.wrapper}>
        <div className={styles.topContent}>
          <div className={styles.topLeft}>
            <div>
              <h4 className={styles.title}>{title}</h4>
              <div className={styles.companyName}>{companyName}</div>
            </div>
            <div className={styles.date}>{dayjs(date).format("DD/MM/YY HH:mm")}</div>
          </div>
          <div className={styles.topRight}>
            {image && <img src={image} alt={"signature"} className={styles.image} />}
            <SignatureCanvas
              ref={sigCanvasRef}
              penColor='#003e47'
              canvasProps={{ width: 240, height: 146, className: styles.canvas }}
              onBegin={onSignatureChange}
            />
          </div>
        </div>
        <div className={styles.bottomGradient}>{t("qualified_electronic_signature")}</div>
      </div>
    );
  }
);

export default Signature;
