import React, { useRef, useState } from "react";
import peopleEng from "../../assets/videos/people_eng.mp4";
import people from "../../assets/videos/people.mp4";
import { useTranslation } from "react-i18next";
import styles from "./ExpandableVideo.module.scss";

const ExpandableVideo = () => {
  const [playVideo, setPlayVideo] = useState(false);
  const vidRef = useRef(null);
  const { t, i18n } = useTranslation("homepage");

  const handlePlayVideo = () => {
    vidRef.current.play();
    setPlayVideo(true);
  };

  const handlePauseVideo = () => {
    vidRef.current.pause();
    setPlayVideo(false);
  };

  return (
    <>
      <div
        className={`${playVideo ? styles.video_wrapper_active : ""} ${styles.video_wrapper} ${
          styles.root
        }`}
      >
        {playVideo ? (
          <div className={styles.video_wrapper_play}>
            <svg
              width='58'
              style={{ margin: "auto" }}
              onClick={() => handlePauseVideo()}
              className={styles.close_video}
              height='58'
              viewBox='0 0 58 58'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='29' cy='29' r='28.5' stroke='#F17F16' />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M22.7216 20.8067C22.5264 20.6115 22.2098 20.6115 22.0145 20.8067L21.3074 21.5138C21.1122 21.7091 21.1122 22.0257 21.3074 22.2209L28.0858 28.9993L21.3072 35.7778C21.112 35.9731 21.112 36.2897 21.3072 36.4849L22.0143 37.1921C22.2096 37.3873 22.5262 37.3873 22.7214 37.1921L29.5 30.4135L36.278 37.1915C36.4733 37.3868 36.7898 37.3868 36.9851 37.1915L37.6922 36.4844C37.8875 36.2891 37.8875 35.9725 37.6922 35.7773L30.9142 28.9993L37.692 22.2215C37.8872 22.0262 37.8872 21.7096 37.692 21.5144L36.9849 20.8073C36.7896 20.612 36.473 20.612 36.2778 20.8073L29.5 27.5851L22.7216 20.8067Z'
                fill='#F17F16'
              />
            </svg>
          </div>
        ) : null}

        <video
          controls={playVideo}
          onEnded={() => {
            setPlayVideo(false);
          }}
          ref={vidRef}
        >
          <source
            src={i18n.language === "en" ? peopleEng : people}
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
          />
        </video>
        {!playVideo ? (
          <div className={styles.wrapper_controls} onClick={() => handlePlayVideo()}>
            <span>{t("video_text")}</span>
            <svg
              width='80'
              height='80'
              viewBox='0 0 80 80'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='40' cy='40' r='30' fill='#49BACA' />
              <path
                d='M35.58 48.787C35.8928 48.9434 36.2036 48.9942 36.5164 48.9942C36.88 48.9942 37.2964 48.8906 37.6093 48.6306L48.1127 41.454C48.6327 41.0904 48.9456 40.4669 48.9456 39.8412C48.9456 39.2176 48.6328 38.594 48.062 38.2284L37.5564 31.263C36.9328 30.8466 36.2036 30.8466 35.5293 31.1594C34.9057 31.523 34.4893 32.1466 34.4893 32.8758V47.0193C34.54 47.7993 34.9036 48.4757 35.5801 48.7864L35.58 48.787Z'
                fill='white'
              />
            </svg>
          </div>
        ) : null}
      </div>
      {playVideo && <div className={styles.placeholder_container} />}
    </>
  );
};

export default ExpandableVideo;
