// SignaturePreviewPopup.tsx
import { Col, ModalProps, Row } from "antd";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import React, { useState, useRef, useCallback, useMemo, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import { RootState } from "../../../store";
import { getFullNameFromUserData } from "../../../utils/general";
import { Button } from "../../ui-components";
import StyledModal from "../../ui-components/StyledModal";
import Signature from "../../Signature";

import styles from "./SignaturePreviewPopup.module.scss";
import { UploadIcon } from "../../../assets/icons";
import { getSignature, uploadSignature } from "../../../api/userApi";
import { getMyTeam } from "../../../api/teamApi";
import { Team } from "../../../types/team";

type SignaturePreviewPopupProps = {
  onClose?: () => void;
  onConfirm?: () => void;
} & ModalProps;

type SignatureRef = {
  getSignatureFile: () => File;
  clearCanvas: () => void;
} | null;

export default function SignaturePreviewPopup({
  onClose,
  onConfirm,
  ...props
}: SignaturePreviewPopupProps) {
  const { t } = useTranslation("dashboard", { keyPrefix: "settings" });
  const { t: tGlobal } = useTranslation("dashboard", { keyPrefix: "global" });
  const { user_data } = useSelector((state: RootState) => state.user);

  const [file, setFile] = useState<File | string | null>(null);
  const [myTeam, setMyTeam] = useState<Team | null>(null);
  const [hasDrawn, setHasDrawn] = useState(false);
  const signatureRef = useRef<SignatureRef>(null);

  const { getInputProps, open } = useDropzone({
    noClick: true,
    multiple: false,
    accept: {
      "image/png": [".png"],
    },
    onDropAccepted: onFileDrop,
  });

  function onFileDrop(files: File[]) {
    setFile(files[0]);
    console.log(signatureRef.current);
    if (signatureRef.current) {
      signatureRef.current.clearCanvas();
    }
  }

  const submitSignature = (currentFile: File | string) => {
    console.log({ currentFile, file, hasDrawn });
    if (!file && !hasDrawn) return;
    if (typeof file === "string" && !hasDrawn) return onConfirm?.();
    console.log({ file });
    try {
      const res = uploadSignature(currentFile);
      console.log(res);
      onConfirm?.();
    } catch (e) {
      console.log({ e });
    }
  };

  const getCurrentSignature = async () => {
    try {
      const res = await getSignature();
      setFile(res);
    } catch (e) {
      console.log({ e });
    }
  };

  const fetchMyTeam = async () => {
    try {
      const res = (await getMyTeam()) as Team;
      setMyTeam(res);
    } catch (e) {
      console.log({ e });
    }
  };

  useEffect(() => {
    fetchMyTeam();
    getCurrentSignature();
  }, []);

  const handleConfirm = useCallback(() => {
    if (file) {
      submitSignature(file);
    } else if (signatureRef.current) {
      const signatureFile = signatureRef.current.getSignatureFile();
      submitSignature(signatureFile);
    }
  }, [onConfirm, file, hasDrawn]);

  const handleClose = useCallback(() => {
    if (file) setFile(null);
    if (hasDrawn) setHasDrawn(false);
    if (signatureRef.current) {
      signatureRef.current.clearCanvas();
    }
    getCurrentSignature();
    onClose?.();
  }, [file, onClose]);

  const handleSignatureChange = useCallback(() => {
    if (file) setFile(null);
    setHasDrawn(true);
  }, [file]);

  const fileURL = useMemo(() => {
    if (file) {
      if (typeof file === "string") return file;
      return URL.createObjectURL(file);
    }
    return undefined;
  }, [file]);
  return (
    <StyledModal
      width={520}
      title='Signature preview'
      footer={
        <Row gutter={16} justify='end'>
          <Col span={6}>
            <Button secondary color='red' handleClick={handleClose}>
              {tGlobal("cancel")}
            </Button>
          </Col>
          <Col span={6}>
            <Button disabled={!hasDrawn && !file} primary color='green' handleClick={handleConfirm}>
              {tGlobal("confirm")}
            </Button>
          </Col>
        </Row>
      }
      {...props}
    >
      <div className={styles.content}>
        <Signature
          ref={signatureRef}
          image={fileURL}
          title={getFullNameFromUserData(user_data)}
          companyName={myTeam?.name}
          onSignatureChange={handleSignatureChange}
        />
        <div className={styles.uploadForm}>
          <input type='file' name='signature' hidden {...getInputProps()} />
          <Button color={"blue"} primary handleClick={open} icon={<UploadIcon />}>
            {t("upload_signature")}
          </Button>
          <b className={styles.description}>
            <Trans t={t} i18nKey='e_signature_supported_formats' components={{ b: <b /> }} />
          </b>
        </div>
      </div>
    </StyledModal>
  );
}
