import moment from "moment";
import { INotification, NotificationType } from "../../../interfaces/Notifications";
import { Link } from "react-router-dom";
import styles from "../../styles/notifications.module.scss";
import { Button } from "../../../components/ui-components";
import AttachmentRow from "../../../Admin/pages/components/Attachments/components/AttachmentRow/AttachmentRow";
import { useTranslation } from "react-i18next";

interface IEntity {
  title?: string;
  name?: string;
  id?: number;
}

const NotificationMessage = ({ item, history }: { item: INotification; history: any }) => {
  const { t } = useTranslation("dashboard", { keyPrefix: "notifications" });

  switch (item.type) {
    case NotificationType.document_upload:
      return (
        <div className={styles.message}>
          {t("uploaded_file_to")} <Link to={"/audits"}>{item.audit.company.name}</Link>,{" "}
          <Link to={`/dashboard/${item.audit.id}/overview`}>{item.audit.name}</Link>
          <div className={styles.uploadContainer}>
            <AttachmentRow key={item.id} file={item.document} progress={undefined} />
          </div>
        </div>
      );
    case NotificationType.comment_added:
      const entityName = item?.request ? "request" : item?.document ? "document" : "meeting";
      const entityId =
        entityName === "request"
          ? item?.request?.id
          : entityName === "document"
          ? item?.document?.id
          : item?.meeting?.id;

      const entity = item[entityName] as IEntity;
      return (
        <div className={styles.message}>
          {t("added_comment_to")}{" "}
          <Link to={`/dashboard/${item.audit.id}/${entityName}s/${entityId}`}>
            {entity?.title || entity?.name}
          </Link>
          , <Link to={"/audits"}>{item.audit.company.name}</Link>,{" "}
          <Link to={`/dashboard/${item.audit.id}/overview`}>{item.audit.name}</Link>
        </div>
      );
    case NotificationType.request_created:
      return (
        <div className={styles.message}>
          {t("assigned_new_request")} <Link to={"/audits"}>{item.audit.company.name}</Link>,{" "}
          <Link to={`/dashboard/${item.audit.id}/overview`}>{item.audit.name}</Link>
          <Button
            handleClick={() => history.push(`/dashboard/${item.audit.id}/overview`)}
            secondary
            style={{ width: 140, marginTop: 15 }}
            color={"blue"}
          >
            {t("go_to_request")}
          </Button>
        </div>
      );
    case NotificationType.status_update:
    case NotificationType.comment_mention:
    default:
      return <div></div>;
  }
};

export const NotificationItem = ({
  notification,
  history,
}: {
  notification: INotification;
  history: any;
}) => {
  return (
    <div className={styles.container}>
      {notification.read || <div className={styles.new} />}
      <div className={styles.logos}>
        <div className={styles.creator}>
          {notification.creator?.fullName?.slice(0, 1).toUpperCase()}
        </div>
        {notification.audit.company.logo && (
          <img className={styles.company} src={notification.audit.company.logo} alt={"logo"} />
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.name}>{notification.creator?.fullName}</div>
        <NotificationMessage item={notification} history={history} />
        {notification.text && (
          <div className={styles.textArea}>
            <span className={styles.text}>{notification.text}</span>
          </div>
        )}
        <div className={styles.date}>{moment(notification.createdAt).fromNow()}</div>
      </div>
    </div>
  );
};
