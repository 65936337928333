import { Tag } from "antd";
import { VerificationPendingIcon, VerifiedIcon } from "../../assets/icons";

import styles from "./UserNameWithStatus.module.scss";

type Props = {
  name?: string;
  role?: string;
  signatoryStatus?: string | "verified" | "pending" | null;
  signatory?: boolean;
};

export default function UserNameWithStatus({ name, role, signatoryStatus, signatory }: Props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.name}>{name}</div>
      {signatoryStatus === "verified" ? (
        <VerifiedIcon />
      ) : (
        !!signatory && <VerificationPendingIcon />
      )}
      {role === "guest" && <Tag color='yellow'>Guest</Tag>}
    </div>
  );
}
