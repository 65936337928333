import "./styles/body.css";
import macbook2 from "../assets/images/macbook2.png";
import girl from "../assets/images/girl-with-desktop.svg";
import people from "../assets/videos/people.mp4";
import peopleEng from "../assets/videos/people_eng.mp4";
import undraw from "../assets/images/undraw.svg";
import ninety from "../assets/images/ninety.svg";
import wohen from "../assets/images/wohen.svg";
import weeks from "../assets/images/weeks.svg";
import twentyFour from "../assets/images/twenty-four.svg";
import Button from "../customComponents/Button/Button";
import BlueWindow from "../customComponents/BlueWindow/BlueWindow";
import React, { useRef, useState, useEffect, useCallback } from "react";
import Slider from "react-slick";
import { otherConst } from "../consts/otherConst";
import DifferentlyBlock from "./DifferentlyBlock";
import useResize from "../hooks/useResize";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ExpandableVideo from "./ExpandableVideo/ExpandableVideo";

function Body() {
  const { t, i18n } = useTranslation("homepage");
  const [activeWidthBetweenSlide, setActiveWidthBetweenSlide] = useState();
  const { innerWidth } = useResize();
  const history = useHistory();

  const sliderData = [
    {
      text: "slider_1_text",
      from_name: "slider_1_from",
      from_position: "slider_1_pos",
    },
    {
      text: "slider_2_text",
      from_name: "slider_2_from",
      from_position: "slider_2_pos",
    },
    {
      text: "slider_3_text",
      from_name: "slider_3_from",
      from_position: "slider_3_pos",
    },
  ];

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1.2,
    centerMode: true,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1.2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.4,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const getWidthBetween = useCallback(() => {
    let elem = document.querySelectorAll(".elipses");
    if (!elem || !elem[0] || !elem[0].clientHeight || !elem[0].clientWidth) {
      return;
    }

    if (innerWidth > 900) {
      setActiveWidthBetweenSlide(elem[0]?.clientWidth - 170);
    } else {
      setActiveWidthBetweenSlide(elem[0]?.clientHeight - 170);
    }
  }, [innerWidth]);

  useEffect(() => {
    getWidthBetween();

    window.addEventListener("resize", () => {
      getWidthBetween();
    });

    return () => {
      window.removeEventListener("resize", () => {
        getWidthBetween();
      });
    };
  }, [getWidthBetween]);

  // useEffect(() => {
  //     if (playVideo) {
  //         document.body.style.overflow = "hidden";
  //     } else {
  //         document.body.style.overflow = "unset";
  //     }
  // }, [playVideo])

  return (
    <div className={"times-wrapper"}>
      <div className='first-title'>
        <div className={"more-time"}>
          <span className='title'>{t("title")}</span>
          <p className='text-first-block'>{t("description")}</p>
          <Link to={`/kontakt/besprechung`} className={"btn_orange btn_default"}>
            {t("button_text")}
          </Link>
        </div>
        <img className={"macbook"} src={macbook2} alt={"macbook"} />
      </div>

      <ExpandableVideo height={504} />

      <div className='second-title'>
        <div className={"left-block"}>
          <img className={"girl"} src={girl} alt={"girl"} />
        </div>

        <div className={"right-block-content"}>
          <span className='right-partner'>{t("aivo_partner")}</span>
          <p className='text-first-block'>{t("aivo_partner_description_1")}</p>
          <span className={"second-text-block"}>{t("aivo_partner_description_2")}</span>
        </div>
      </div>
      <div className={"return-time"}>
        <span className='right-partner'>{t("win_time")}</span>
        <div className={"block-return-time"}>
          <div className={"blocks-return-time1"}>
            <img src={ninety} className={"ninety"} alt={"ninety"} />
            <span className={"third-title-return-time"}>{t("win_time_1")}</span>
          </div>
          <div className={"blocks-return-time2"}>
            <img src={twentyFour} className={"twenty_four"} alt={"twenty-four"} />
            <span className={"third-title-return-time"}>{t("win_time_2")}</span>
          </div>
          <div className={"blocks-return-time3"}>
            <img src={i18n.language === "en" ? weeks : wohen} className={"wohen"} alt={"wohen"} />
            <span className={"third-title-return-time"}>{t("win_time_3")}</span>
          </div>
        </div>
      </div>

      <DifferentlyBlock innerWidth={innerWidth} />

      <div className={"your-revision"}>
        <div className={"your-revision-info"}>
          <h1>{t("audit_steps_title")}</h1>
          <span className={"revision-text"}>{t("audit_steps_description")}</span>
          <Button
            text={t("audit_steps_button")}
            onClick={() => {
              history.push("/revision");
            }}
            className={"btn_orange"}
          />
        </div>
        <div className={"elipses"}>
          {otherConst.revision_5_steps.map((i, index) => (
            <div className='elipses__every' key={index}>
              <div className={"elipses-photos"}>
                <img src={i.img} alt={"logo"} />
              </div>
              <p className={"title-for-elipses"}>{t(i.title)}</p>
            </div>
          ))}
          <svg
            width={`${activeWidthBetweenSlide}`}
            className={"line"}
            height='43'
            viewBox={`0 0 ${activeWidthBetweenSlide} 43`}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M909.5 8.69791C883.205 17.2916 856.537 25.4221 826.815 31.7845C682 62.7846 555.418 12.1168 463 2.28471C370.582 -7.54737 232.501 34.4501 143.289 39.9295C103.765 42.357 53.2219 33.2391 0.999952 21.7822'
              stroke='#F17F16'
            />
          </svg>
        </div>
      </div>

      <div className={"reviews"}>
        <div className={"navigation-reviews"}>
          <h1>{t("others_say_title")}</h1>
          <div className={"vectors"}></div>
        </div>
        <div className={"sliders"}>
          <Slider
            {...settings}
            beforeChange={(event, slick, currentSlide, nextSlide) => {
              let direction,
                slideCountZeroBased = slick.slideCount - 1;

              if (nextSlide === currentSlide) {
                direction = "same";
              } else if (Math.abs(nextSlide - currentSlide) === 1) {
                direction = nextSlide - currentSlide > 0 ? "right" : "left";
              } else {
                direction = nextSlide - currentSlide > 0 ? "left" : "right";
              }

              // Add a temp CSS class for the slide animation (.slick-current-clone-animate)
              if (direction === "right") {
                let g2Parms = `.slick-cloned[data-slick-index=${
                  nextSlide + slideCountZeroBased + 1
                }]`;
                let bt = document.querySelector(g2Parms, ".slick-slide");
                bt.classList.add("slick-current-clone-animate");
              }

              if (direction === "left") {
                let g2Parms = `.slick-cloned[data-slick-index=${
                  nextSlide - slideCountZeroBased - 1
                }]`;
                let bt = document.querySelector(g2Parms, ".slick-slide");
                bt.classList.add("slick-current-clone-animate");
              }
            }}
          >
            {sliderData.map((i) => (
              <div className={"review1"}>
                <span className={"review"}>"{t(i.text)}"</span>
                <p className={"from-who-review"}>{t(i.from_name)}</p>
                <p className={"from-who-review"}>{t(i.from_position)}</p>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <BlueWindow
        title={t("blue_window_btn_team")}
        linkTo={"/kontakt/besprechung"}
        desc={t("blue_window_desc_revision")}
        src={undraw}
        btnText={t("blue_window_btn_revision")}
      />
    </div>
  );
}

export default Body;
