import clsx from "clsx";
import { Fragment, useEffect, useMemo, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { markAsDone, setTaskStatus, toggleTaskStatus } from "../../../../../api/statusApi";
import useQueryParams from "../../../../../hooks/useQueryParams";
import useClick from "../../../../../hooks/useClick";
import StatusTask from "../StatusTask";

import StatusStep from "./StatusStep/StatusStep";
import OpenedStep from "./OpenedStep";
import CreateEditStep from "./CreateEditStep";

import { PlusCircleIcon } from "../../../../../assets/icons";

import { STATUS_FILTER_OPTIONS, STATUS_TRANSLATION } from "../../Status.constants";
import styles from "./StatusView.module.scss";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  const newStep = removed;

  if (!newStep.editModeAction) {
    newStep.editModeAction = "update";
  }

  result.splice(endIndex, 0, newStep);

  return result;
};

const StatusView = ({
  data,
  setData,
  editMode,
  selectedStatusGroup,
  updatedSteps,
  idAudit,
  hasAdminPermissions,
  stealthRefetchAsync,
  requests,
  meetings,
  documents,
  isUnsynced,
  ownershipFilter = [],
  auditKind,
  activeGroupIdx,
}) => {
  const { query, setQueryParams, removeQueryParams } = useQueryParams();
  const [openedStep, setOpenedStep] = useState(null);
  const [isDragActive, setIsDragActive] = useState(false);
  const [createStepIdx, setCreateStepIdx] = useState(null);
  const [editStep, setEditStep] = useState(null);
  const [unfilteredSteps, steps] = useMemo(() => {
    const unfilteredSteps = updatedSteps[selectedStatusGroup] || data?.steps || [];
    return [
      unfilteredSteps,
      unfilteredSteps
        .filter(
          (i) =>
            i.editModeAction !== "delete" &&
            (ownershipFilter.length === 0 || ownershipFilter.some((j) => i.ownership === j))
        )
        .sort((a, b) => a.order - b.order),
    ];
  }, [data?.steps, selectedStatusGroup, updatedSteps, ownershipFilter]);
  const hasClicked = useClick();
  const isEditDisabled = isUnsynced;

  const updateStatusGroup = (newSteps) => {
    setData((state) => ({
      ...state,
      audit: {
        ...state.audit,
        statusGroups: state.audit.statusGroups.map((group) => {
          if (group.id === selectedStatusGroup) {
            return {
              ...group,
              steps: newSteps,
            };
          }
          return group;
        }),
      },
    }));
  };

  function onDragEnd(result) {
    setIsDragActive(false);
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newSteps = reorder(steps, result.source.index, result.destination.index).map(
      (step, idx) => ({
        ...step,
        order: idx + 1,
        editModeAction: step.editModeAction || "update",
      })
    );
    updateStatusGroup(newSteps);
    // setState({ quotes });
  }

  const callStatusAction = async (stepId, status) => {
    if (hasAdminPermissions) {
      await setTaskStatus(idAudit, stepId, { status });
    } else {
      if (status === "done") {
        await markAsDone(idAudit, stepId);
      } else {
        await toggleTaskStatus(idAudit, stepId);
      }
    }
  };

  const handleMarkAsDone = async (stepId, isSubtask, status = "done") => {
    if (isSubtask) {
      await callStatusAction(stepId, status);
      setOpenedStep((state) => ({
        ...state,
        subtasks: state.subtasks.map((i) => (i.id === stepId ? { ...i, status } : i)),
      }));
      await stealthRefetchAsync();
      return;
    }
    let stepIdx = -1;
    const newSteps = unfilteredSteps
      .sort((a, b) => a.order - b.order)
      .map((step, idx) => {
        if (step.id === stepId) {
          stepIdx = idx;
          return {
            ...step,
            status,
          };
        }
        return step;
      });
    if (stepIdx === -1) {
      return;
    }
    updateStatusGroup(newSteps);
    setOpenedStep(null);
    removeQueryParams(["openedStep"]);
    await callStatusAction(stepId, status);
    await stealthRefetchAsync();
  };

  const handleStepClick = (step, isEdit = editMode) => {
    if (!hasAdminPermissions) return;

    if (isEdit) {
      if (isEditDisabled || step.status === "done") return;

      setEditStep(step);
      setCreateStepIdx(step.id);
    } else {
      setOpenedStep(step);
    }
    setQueryParams({ openedStep: step.id });
  };

  const finishCreate = (newStep) => {
    const newSteps = [...unfilteredSteps];
    newSteps.splice(createStepIdx, 0, newStep);
    updateStatusGroup(
      newSteps.map((step, idx) => ({
        ...step,
        order: idx + 1,
      }))
    );
    setCreateStepIdx(null);
  };

  const finishUpdate = (updatedStep) => {
    const newSteps = unfilteredSteps.map((step) => {
      if (step.id === updatedStep.id) {
        return updatedStep;
      }
      return step;
    });
    updateStatusGroup(newSteps);
  };

  const finishDelete = (step) => {
    const newSteps =
      step.editModeAction === "create"
        ? unfilteredSteps
            .filter((i) => i.id !== step.id)
            .map((i) => (i.order > step.order ? { ...i, order: i.order - 1 } : i))
        : unfilteredSteps.map((i) =>
            i.id === step.id
              ? { ...i, editModeAction: "delete" }
              : i.order > step.order
              ? { ...i, order: i.order - 1 }
              : i
          );
    updateStatusGroup(newSteps);
  };

  const openCreateStep = (idx) => {
    if (isEditDisabled) return;
    setCreateStepIdx(idx);
    setQueryParams({ createStep: idx });
  };

  const handleCloseStatusView = () => {
    setOpenedStep(null);
    setEditStep(null);
    removeQueryParams(["openedStep", "openedSubtask"]);
  };

  useEffect(() => {
    if (query.createStep) {
      setCreateStepIdx(query.createStep);
    }
    if (query.openedStep && steps.find((step) => step.id === +query.openedStep)) {
      handleStepClick(
        steps.find((step) => step.id === +query.openedStep),
        query.edit
      );
    }
  }, [steps]);

  const hasOpenedStep = query.openedStep || query.openedSubtask || query.createStep;
  const noAnimation = hasOpenedStep && !hasClicked;

  return (
    <div
      className={clsx(styles.root, {
        [styles.editMode]: editMode,
        [styles.dragActive]: isDragActive,
      })}
    >
      <CreateEditStep
        createStepIdx={createStepIdx}
        setCreateStepIdx={setCreateStepIdx}
        idAudit={idAudit}
        finishCreate={finishCreate}
        finishUpdate={finishUpdate}
        finishDelete={finishDelete}
        statusGroup={selectedStatusGroup}
        stepData={editStep}
        setStepData={setEditStep}
        noAnimation={noAnimation}
        auditKind={auditKind}
      />
      <OpenedStep
        setData={setData}
        openedStep={openedStep}
        setOpenedStep={setOpenedStep}
        hasAdminPermissions={hasAdminPermissions}
        handleMarkAsDone={handleMarkAsDone}
        requests={requests}
        meetings={meetings}
        documents={documents}
        idAudit={idAudit}
        allowSubtask
        noAnimation={noAnimation}
      />
      {/*<StatusTask*/}
      {/*  data={openedStep || editStep}*/}
      {/*  documents={documents}*/}
      {/*  editMode={!!editStep}*/}
      {/*  open={Boolean(editStep || openedStep)}*/}
      {/*  onClose={handleCloseStatusView}*/}
      {/*/>*/}
      <div className={styles.head}>
        <span className={styles.numeration}>{activeGroupIdx + 1}.</span>
        <span className={styles.title}>{data.name}</span>
      </div>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setIsDragActive(true)}>
        <Droppable droppableId='status-step'>
          {(provided) => (
            <div className={styles.steps} ref={provided.innerRef} {...provided.droppableProps}>
              {steps.map((step, idx) => {
                const isLast = idx !== steps?.length - 1;
                const unfilteredIdx = unfilteredSteps.findIndex((j) => step.id === j.id);
                return (
                  <Fragment key={idx}>
                    {/*{idx !== 0 &&*/}
                    {/*  step.status === "not_started" &&*/}
                    {/*  (*/}
                    <PlusCircleIcon
                      className={styles.addNew}
                      onClick={() => openCreateStep(unfilteredIdx)}
                    />
                    {/*)}*/}
                    <StatusStep
                      step={step}
                      editMode={editMode}
                      isLast={isLast}
                      handleStepClick={handleStepClick}
                      idx={idx}
                      hasAdminPermissions={hasAdminPermissions}
                      handleMarkAsDone={handleMarkAsDone}
                      isEditDisabled={isEditDisabled}
                      auditKind={auditKind}
                      disableDrag={
                        ownershipFilter.length !== 0 &&
                        ownershipFilter.length !== STATUS_FILTER_OPTIONS.length
                      }
                    />
                  </Fragment>
                );
              })}
              <PlusCircleIcon
                className={styles.addNew}
                onClick={() => openCreateStep(unfilteredSteps.length)}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default StatusView;
